import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "../components/button"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/menu.css"
import Newsletter from "../components/newsletter"


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1 style={{ fontWeight: 800, marginTop: 20 }}>{post.frontmatter.title}</h1>
        
        <MDXRenderer>{post.body}</MDXRenderer>
       
        <br/>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li
          style={{
            padding: 5,
          }}>
            {previous && (
              <Link to={`/blog${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li
          style={{
            padding: 5,
          }}>
            {next && (
              <Link to={`/blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <div className="BlogSub">
        <h3>Let's continue the discussion <span role="img" aria-label>💬</span> </h3>
        <p>If you're interested in such topics, you should sign up for my newsletter, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on design, business, & life. </p>
          <Newsletter/>
        </div>
        <br/>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
